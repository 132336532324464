@import './assets/css/animations.css';
@import './assets//css/variables.css';
@import './assets//css/pseudo.css';

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  overflow-wrap: break-word;
}

html,
body {
  height: 100%;
  position: relative;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select,
div[contenteditable='true'] {
  font: inherit;
  width: 100%;
  height: 100%;
  outline: none;
  background: transparent;
  border: none;
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--neutral200);
}

#root {
  height: 100%;
}

#root,
#__next {
  isolation: isolate;
}

@font-face {
  font-family: 'Inter';
  /* prettier-ignore */
  src: local('Inter'),
   /* prettier-ignore */
    url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype');
}

/* custom scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #e1e4e9;
  border-radius: 4px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #e1e4eaaa;
}
