:root {
  --neutral050: #f9fafc;
  --neutral100: #f5f6fa;
  --neutral200: #e8e8ef;
  --neutral300: #d5d6e0;
  --neutral400: #bfc0cb;
  --neutral500: #a1a2ad;
  --neutral700: #545562;
  --neutral800: #35363e;
  --neutral900: #1f2025;
  --primary050: #d9f1ff;
  --primary100: #c1e8ff;
  --primary200: #a1ddff;
  --primary300: #7bcfff;
  --primary400: #41b9ff;
  --primary500: #348fea;
  --primary600: #0c57bf;
  --primary700: #0e499b;
  --primary800: #002f6e;
  --primary900: #002251;
  --success050: #a1f1b3;
  --success300: #74e08c;
  --success500: #51c46b;
  --success700: #32ab4d;
  --success900: #189233;
  --warning100: #ffe994;
  --warning300: #ffdb51;
  --warning500: #f5c200;
  --warning700: #e5a501;
  --warning900: #cc8801;
  --error100: #ffc3b9;
  --error300: #fa907d;
  --error500: #e9664f;
  --error700: #c34630;
  --error900: #a12d19;
  --violet100: #eec8ff;
  --violet300: #e4a6ff;
  --violet500: #d081f3;
  --violet700: #b960e0;
  --violet900: #9a38c6;
  --sharpWhite: #ffffff;
  --sharpBlack: #000000;
}
