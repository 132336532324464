/* Button Loading Ellipsis - START */
@keyframes ellipsisDot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsisDot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes ellipsisDot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* Button Loading Ellipsis - END */

/* Roller Loading - START */
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Roller Loading - END */

/* Static Spinner - START */

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
/* Static Spinner - END */

/* Skeleton Loading - START */
.pulse {
  position: relative;
  visibility: hidden;
}

.pulse > * {
  opacity: 0;
}

.pulse::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse-animation 1.25s infinite ease-in-out;
  visibility: visible;
  border-radius: 8px;
}

@keyframes pulse-animation {
  0% {
    background-color: rgba(165, 165, 165, 0.15);
  }
  40% {
    background-color: rgba(165, 165, 165, 0.25);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.15);
  }
}

/* Skeleton Loading - END */
